import { Accordion, Button, Table } from "react-bootstrap";

var questions = [
  "¿Qué son las líneas de apuestas y dónde ver?",
  "¿Cómo retirar dinero de Codere México?",
  "¿Qué son las apuestas deportivas?",
  "¿Cómo funcionan los momios americanos?",
  "¿Cómo funcionan las apuestas deportivas?",
  "¿Cómo apostar en línea?",
];
var answers = [
  "Las líneas de apuestas se determinan por un valor numérico para establecer la ventaja de un equipo con goles, puntos, carreras, córners, etc; y éstas se pueden identificar mediante los signos (+) o (-), además le sirven al jugador para tener referencia respecto a los diferentes mercados de apuestas de un evento deportivo determinado. Las puedes encontrar en la sección de Deportes y Deportes en vivo.",
  "Primer debes hacer una verificación de tu cuenta y después elegir el método de retiro. Recuerda que tenemos las opciones de transferencia bancaria, cobro en efectivo en de nuestras sucursales, así como en Oxxo, entre otras. Para cualquier problema que tengas en este proceso puedes comunicarte a nuestro contacto: +52 56 2573 2721",
  "Es la opción de apostar una cantidad de dinero a un resultado probable que pueda resultar de un encuentro deportivo de cualquier disciplina.",
  "Cuando el momio es positivo, indica la cantidad que podrías ganar si apuestas 100 pesos, por ejemplo; si apuesta $100 con momio de +450, ganarías $550. Por otro lado, si el momio es negativo, indica la cantidad que debes apostar para obtener una ganancia de 100 pesos.",
  `Las apuestas deportivas ofrecen una variedad de opciones de cuotas o momios, desde el resultado final de un partido hasta detalles específicos como el número de goles o tiros de esquina. Los "momios", determinados por analistas o traders, reflejan las probabilidades de que un suceso ocurra y las ganancias potenciales al realizar una apuesta, y sitios como Codere proporcionan un entorno seguro para realizar apuestas, respaldado por el permiso de SEGOB.`,
  "Entra a Codere.mx la casa de apuestas de los GOATS, donde podrás apostar en cualquier evento deportivo a través del sitio web o aplicación móvil para Android y iOS, asegurando un proceso seguro. Nuestras plataformas ofrecen la visualización de estimaciones de ingresos, la posibilidad de cerrar apuestas antes del final del evento y la oportunidad de explorar gran variedad de combinaciones de apuestas, además de aprovechar promociones como apuestas gratis, devoluciones de efectivo y ofertas especiales",
];

function Content() {
  const prefix = "https://apuestas.codere.mx/es_MX/";

  return (
    <div className="content-container container">
      <h1 className="bottomContentTitlexxx text-center">
        La casa #1 de Apuestas Deportivas en México
      </h1>
      <p>
        Bienvenido a la nueva forma de vivir tus deportes favoritos, nuestra
        oferta es única en el mundo de las apuestas online, con gran variedad de
        mercados en los mejores eventos deportivos.
      </p>

      <div className="row justify-content-center">
        <div className="col-12 col-sm-4 left-col">
          <h2>Apuesta segura en línea</h2>
          <p>
            En Codere, entendemos la importancia de la seguridad y la confianza
            en el juego en línea. Es por eso que nos comprometemos a
            proporcionar un entorno de juego seguro y transparente, donde la
            diversión va de la mano con la responsabilidad. Además, nuestras
            promociones y bonificaciones exclusivas están diseñadas para
            amplificar tu experiencia de juego y brindarte oportunidades
            adicionales de ganar y maximizar tu diversión.
          </p>

          <div>
            <h3>¿Qué tipo de apuestas deportivas existen? </h3>
            <p>
              La casa de apuestas deportivas Codere, es el mejor sitio para
              jugar ya que ofrece diferentes opciones de apuestas y cuenta con
              una gran variedad de eventos deportivos. Recuerda que contamos con
              estadísticas como apoyo para que puedas elegir la apuesta más
              adecuada:
            </p>
            <p>
              <u>Apuestas 1X2:</u> Es la jugada más básica, ya que solo consiste
              en elegir el resultado del partido. El 1 corresponde al equipo
              local, la X al empate y el 2 al equipo visitante.
            </p>
            <p>
              <u>Doble oportunidad:</u> En esta apuesta mejoras tus
              posibilidades de ganar, ya que eliges dos resultados de los tres
              posibles: gana local y empate, empate y visitante, gana local y
              gana visitante
            </p>
            <p>
              <u>Marcan ambos equipos:</u> En esta apuesta hay dos opciones para
              elegir: SÍ y NO. Si juegas el SÍ, los dos equipos tienen que hacer
              gol; si elegiste NO, al menos uno de los dos equipos no debe
              anotar para que la jugada sea ganadora.
            </p>
            <p>
              <u>Marcador exacto:</u> En esta jugada debes acertar el resultado
              final del partido en 90 minutos con los goles exactos por equipo y
              existen varias opciones disponibles con los marcadores más
              comunes, por ejemplo: 1-0, 1-1, 1-2.
            </p>
          </div>
        </div>
        <div className="col-12 col-sm-4 middle-col">
          <p>
            <u>Hándicap:</u> Es una apuesta en la que se otorgan goles o puntos
            a favor o en contra a los equipos, con la finalidad de mejorar los
            momios en partidos muy disparejos. Por ejemplo, si un partido eliges
            -1, el equipo debe ganar por dos o más goles o puntos de diferencia
            para cobrar la apuesta.
          </p>
          <p>
            <u>Hándicap asiático:</u> El sistema es igual que en el hándicap
            tradicional, con la diferencia de que en el futbol en caso de que se
            gana o se pierda exactamente por la diferencia elegida, tu jugada se
            anula.
          </p>
          <p>
            <u>Primer equipo en marcar:</u> Es una apuesta muy sencilla, solo
            debes elegir al equipo que crees que anotará primero, ya sea local o
            visitante, aunque también existe la opción de que ninguno hace gol.
          </p>
          <p>
            <u>1x2 al descanso resultado 1era parte y resto del partido:</u>
            Es una apuesta combinada, pues se elige el resultado del primer
            tiempo (local, empate, visitante) y también el resultado al final
            del encuentro. Es decir, puedes elegir empate al medio tiempo y
            ganador local al final del juego.
          </p>
          <p>
            <u>Altas y bajas (en total o por partes de partido):</u> La apuesta
            se termina por la cantidad de puntos o goles en un partido, si se
            eligen altas se deben anotar más del total elegido y con bajas deben
            de anotarse menos.
          </p>
          <p>
            <u>Apuestas combinadas:</u> Son apuestas predeterminadas en las que
            se deben acertar dos o más opciones, por ejemplo, 1x2 y ambos
            anotan.
          </p>
          <p>
            <u>Apuestas sin empate:</u> Es una jugada en la que se reduce el
            riesgo, pues solo puedes jugar al ganador y en caso de que se dé el
            empate, la apuesta queda nula.
          </p>
          <p>
            <u>Número exacto de anotaciones:</u> La apuesta consiste en acertar
            cuántos goles se anotarán en el partido y debes elegir entre las
            opciones habilitadas.
          </p>
          <p>
            <u>Anotadores:</u> En esta apuesta hay varias opciones para elegir,
            como si un jugador hará gol en cualquier momento, si será el primero
            o el último en marcar y hasta si logrará dos o más goles y hasta Hat
            Trick.
          </p>
          <p></p>
        </div>
        <div className="col-12 col-sm-4 right-col">
          <div>
            <h2>El Mejor Casino Online está en Codere</h2>
            <p>
              Si estás buscando la versión online de Yak Casino, debes saber que
              Yak es la versión física dónde encontrarás los slots de Codere en
              México. Recuerda que codere.mx es más que un simple sitio de
              apuestas, ya que también ofrecemos la mejor plataforma en México
              de{" "}
              <a href="https://www.codere.mx/casino-en-vivo">casino en vivo</a>,
              con una diversidad única de juegos de casino como: mesas de{" "}
              <a href="https://www.codere.mx/library/ruleta.html">ruleta</a>{" "}
              online, los clásicos <a href="https://www.codere.mx/yak">slots</a>
              , baccarat en vivo,{" "}
              <a href="https://www.codere.mx/library/blackjack.html">
                blackjack
              </a>
              , entre muchas de opciones más.
            </p>
            <h3>Juega casino en vivo</h3>
            <p>
              Lo que hace más especial tu experiencia en Codere es la fabulosa
              calidad de nuestras plataformas de streaming para jugar
              completamente en vivo. Estas no solo ofrecen una transmisión
              fluida y sin interrupciones, sino que también garantizan una
              reproducción visual y auditiva excepcional. Cada giro, cada
              apuesta, se presenta de manera nítida y envolvente, permitiéndote
              disfrutar de la emoción de los juegos con un realismo que supera
              las barreras de la pantalla.
            </p>
            <p>
              Atrévete a sumergirte en la experiencia Codere, donde cada giro de
              la ruleta, cada mano de cartas, es una invitación a descubrir el
              emocionante mundo del{" "}
              <a href="https://www.codere.mx/casino">casino online</a> en
              México.
            </p>
            <p>
              Tu próxima aventura de juego te espera, ¿estás listo para vivirla
              con nosotros? ¡Bienvenido a Codere, donde el azar y la emoción se
              encuentran para crear un momento inolvidable en el mundo del
              casino online!
            </p>
          </div>
        </div>
      </div>
      <Table striped bordered hover variant="dark" className="mb-4 w-75">
        <tbody>
          <tr>
            <td>🏆 Inicio de operaciones</td>
            <td>2019</td>
          </tr>
          <tr>
            <td>⚽ Apuestas Deportivas </td>
            <td>Apuestas futbol, Apuestas Superbowl, Apuestas NBA y más</td>
          </tr>
          <tr>
            <td>🎲 Licencia</td>
            <td>
              Oficio número 2768, DGG/SP/442/1997, DGJS/DGAAD/DCRCA/1566/2018,
              DGJS/1018/2015, DGJS/234/2019 y DGJS/1427/2023
            </td>
          </tr>
          <tr>
            <td>✔️ Moneda</td>
            <td>Peso Mexicano</td>
          </tr>
          <tr>
            <td>💰 Depósito mínimo</td>
            <td>$100 MXN</td>
          </tr>
          <tr>
            <td>💸 Retiro</td>
            <td> Mínimo $10 MNX (Diez Pesos 00/100 M.N.)</td>
          </tr>
          <tr>
            <td>💳 Formas de Pago</td>
            <td>
              Visa, Paynet, MasterCard, Transferencia Bancaria, Teleingreso,
              Oxxo, SPEI
            </td>
          </tr>
        </tbody>
      </Table>

      <section className="mt-5 w-75 m-auto faq">
        <h2>FAQ’S apuestas deportivas</h2>
        <Accordion className="content-accordion">
          {answers.map((answer, index) => {
            return (
              <Accordion.Item key={index} eventKey={index}>
                <Accordion.Header as="h3">{questions[index]}</Accordion.Header>
                <Accordion.Body>
                  <p>{answer}</p>
                </Accordion.Body>
              </Accordion.Item>
            );
          })}
        </Accordion>
      </section>

      <div className="mt-4 text-center animate-sec">
        <div className="hr mb-2" />
        <span className="animate color-gr fw-bold">
          ¡ANÍMATE Y DESCUBRE TODAS LAS VENTAJAS QUE TE OFRECE CODERE!
        </span>
        <div className="hr mt-2" />
        <div className="mt-4">
          <span className="bono color-gr p-2 fw-bold">BONO DE BIENVENIDA</span>
          <div className="fw-bold fs-2 mt-3 hasta">
            DE HASTA $5,000 PARA JUGAR EN TUS PRIMEROS 3 DEPÓSITOS
          </div>
          <Button href="https://www.codere.mx/registro-deportes?clientType=sportsbook" className="registrate-button m-auto mt-3 fs-4 text-white">
            ¡Regístrate ahora!
          </Button>
          <div className="hr mt-4" />
        </div>
      </div>
    </div>
  );
}

export default Content;
